<template>
    <div class="moon-wrapper">
        <div id="moon-pie-container"></div>

        <div class="moon"></div>
        <div class="shadow"></div>

        <v-rocket></v-rocket>
    </div>
</template>

<style lang="scss">
    .moon-wrapper{
        position:relative;

        #moon-pie-container {
            position:relative;
            width:200px;
            height:200px;
            z-index:2;

            .highcharts-series-group{
                opacity:0.5;
            }

            svg{
                pointer-events:auto;
            }

            .highcharts-credits{
                display:none;
            }
        }

        .moon{
            position:absolute;
            width:79%;
            height:78%;
            top:10%;
            left:50%;
            transform:translateX(-50%);
            z-index:1;

            background-color:red;

            background: transparent url( '/static/img/moon.png' ) no-repeat center center;
            background-size:100% 100%;
        }

        .shadow{
            @extend .moon;

            width:calc( 79% + 1px);
            height:calc( 78% + 1px);

            background:none;
            box-shadow:inset -4px -5px 8px 1px rgba(0,0,0,1);
            border-radius:1000px;

            z-index:2;
            pointer-events:none;
        }

        .rocket{
            pointer-events:none;
        }
    }
</style>

<script>
    import Rocket from '@/app/components/Rocket';
    import Highcharts from 'highcharts';

    export default {

        components: {
            'v-rocket': Rocket
        },

        computed: {
            chartData()
            {
                return this.$store.getters[ 'portfolio/chartData' ];
            },

            numCoins()
            {
                if( this.$store.getters[ 'portfolio/all' ]  )
                {
                    return Object.keys( this.$store.getters[ 'portfolio/all' ] ).length;
                }

                return null;
            },

            colors()
            {
                return this.$store.getters['colors/all'];
            }
        },

        watch: {
            chartData: function( pVal )
            {
                if( performance.now() - this.lastreflow < 10000 )
                {
                    return;
                }
                this.chart.setSeriesData( this.chartData );
                this.chart.reflow();
                this.chart.redraw();

                this.lastreflow = performance.now();
            }
        },

        mounted()
        {
            this.lastreflow = performance.now();
            this.initChart();
            console.log( this.chartData );
        },

        methods: {
            initChart()
            {
                // console.warn( 'chart init', this.chartData );
                this.chart = Highcharts.chart( 'moon-pie-container', {
                    pane: {
                        background: {
                            backgroundColor: 'rgba(0,0,0,0)'
                        }
                    },
                    chart: {
                        plotBackgroundColor: 'transparent',
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',

                        backgroundColor: 'rgba(0,0,0,0)'
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> <br/><span>Price: €{point.price}</span> <br/><span>Holdings: €{point.holdings}</span>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: false,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    legend: {
                        enabled: false
                    },

                    series: [{
                        name: 'Percent',
                        colorByPoint: false,
                        data: this.chartData
                    }]
                });
            },

            reflow()
            {
                this.chart.reflow();
            }
        }
    };
</script>
