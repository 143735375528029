import Vue from 'vue';
import Vuex from 'vuex';

import portfolio from './modules/portfolio';
import colors from './modules/colors';
import prices from './modules/prices';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        portfolio,
        colors,
        prices
    },

    state: {
        value: null,
        startValue: null,
        chartHeight: null
    },

    getters: {
        value: state => state.value,
        startValue: state => state.startValue,
        chartHeight: state => state.chartHeight
    },

    mutations: {
        loadStoreFromLocalStorage(state)
        {
            if( localStorage.getItem( 'crypto-store' ) )
            {
                let savedData = JSON.parse( localStorage.getItem( 'crypto-store' ))

                // delete savedData.colors;

                let data = Object.assign( state, savedData );

                if( !data.portfolio.goal && !data.portfolio.goal.amount )
                {
                    data.portfolio.goal = {
                        coin: null,
                        amount: null
                    };
                }

                this.replaceState( data );
            }

            state.startValue = state.value;
        },

        setValue: ( state, payload ) =>
        {
            state.value = payload;

            if( state.startValue === null )
            {
                state.startValue = payload;
            }
        },

        setChartHeight: ( state, payload ) =>
        {
            state.chartHeight = payload;
        }
    },

    actions: {
        setValue: ( context, payload ) =>
        {
            context.commit( 'setValue', payload );
        },

        setChartHeight: ( context, payload ) =>
        {
            context.commit( 'setChartHeight', payload );
        }
    }
});
