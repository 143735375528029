<template>
    <div class="chart-wrapper">
        <canvas ref="canvas" height="182"></canvas>
    </div>
</template>

<style lang="scss" scoped>
    .chart-wrapper{
    }
</style>

<script>
    import { Chart } from 'chart.js';
    import zoomPlugin from 'chartjs-plugin-zoom';

    import hexRgb from 'hex-rgb';

    import { coinInBtc } from '@/app/mixins';

    export default {
        mixins: [ coinInBtc ],

        data()
        {
            return {
            };
        },

        computed: {
            value()
            {
                return this.$store.getters.value;
            },

            coins()
            {
                return this.$store.getters[ 'portfolio/coins' ];
            },

            prices()
            {
                return this.$store.getters[ 'prices/all' ];
            },

            colors()
            {
                return this.$store.getters[ 'colors/all' ];
            }
        },

        watch: {
            'colors': {
                deep: true,
                handler: function( pVal )
                {
                    this.chart.data.datasets.forEach( dataset =>
                    {
                        dataset.backgroundColor = pVal[ dataset.label ];
                        dataset.backgroundColor = pVal[ dataset.label ];
                    });

                    this.chart.update();
                }
            }
        },

        mounted()
        {
            this.ctx = this.$refs.canvas.getContext( '2d' );

            this.startingSet = [];
            this.startValues = [];

            for( let coin in this.coins )
            {
                this.startValues.push({ coin: coin, value: this.coinInBtc( coin ) * this.prices.XBTUSD });

                let coinColor = this.colors['other'];
                if( this.colors[ coin ] !== undefined )
                {
                    coinColor = this.colors[ coin ];
                }

                let c = hexRgb( coinColor, { format: 'array' });
                c[3] = 0.5;

                let color = 'rgba( ' + c.join( ',' ) + ' )';

                this.startingSet.push({
                    label: coin,
                    data: [ 0 ],
                    backgroundColor: color,
                    borderColor: color,
                    fill: 'origin'
                });
            }

            this.chart = new Chart( this.ctx, {
                type: 'line',
                data: {
                    labels: [],
                    datasets: this.startingSet
                },
                options: {
                    maintainAspectRatio: false,
                    spanGaps: true,
                    responsive: true,
                    layout: {
                        padding: 5
                    },
                    elements: {
                        line: {
                            tension: 0.000001
                        },
                        point: {
                            radius: 1
                        }
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function( tooltipItem, data )
                            {
                                return data.datasets[ tooltipItem.datasetIndex ].label + ': €' + tooltipItem.yLabel;
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true,
                                zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                                color: 'rgba( 255, 255, 255, 0.2 )',
                                drawOnChartArea: true,
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                display: false,
                                autoSkip: false,
                                maxRotation: 0
                            }
                        }]
                    }
                }
            });

            this.chart.data.labels.push( new Date().toLocaleTimeString() );

            setTimeout( () => {
                this.updateInterval = setInterval( this.pushData, 1000 * 5 );
            }, 2000 );
        },

        beforeDestroy()
        {
            if( this.updateInterval )
            {
                clearInterval( this.updateInterval );
            }
        },

        methods: {
            pushData()
            {
                this.chart.data.labels.push( new Date().toLocaleTimeString() );

                this.chart.data.datasets.forEach( dataset =>
                {
                    let price = null;

                    switch( dataset.label )
                    {
                        case 'btc':
                            price = this.coins[ dataset.label ] * this.prices[ 'XBTUSD' ];
                            break;
                        case 'ltc':
                            price = this.coins[ dataset.label ] * this.prices[ 'LTCUSD' ];
                            break;
                        default:
                            let ticker = Object.keys( this.prices ).find( key =>
                            {
                                return key.indexOf( dataset.label.toUpperCase() ) > -1;
                            });

                            price = this.coinInBtc( dataset.label ) * this.prices[ 'XBTUSD' ];
                            break;
                    }

                    let startPrice = this.startValues.find( item => item.coin === dataset.label );

                    dataset.data.push( ( price - startPrice.value ).toFixed( 2 ) );
                });

                this.chart.update();
            }
        }
    };
</script>
