<template>
    <div>
        <!-- <button @click="reInit">Reinit</button>
        <button @click="stopStream">Close stream</button> -->
    </div>
</template>

<style scoped lang="scss">
    div{
        position:absolute;
        top:0.5rem;
        left:0.5rem;

        button{
            display:inline-block;
        }
    }
</style>

<script>
    import Socket from '@/app/sockets/Socket';

    export default {
        props: {
            url: {
                type: String,
                default: ''
            }
        },

        data()
        {
            return {
                preventSocketsOpening: false
            };
        },

        mounted()
        {
            if( !this.preventSocketsOpening )
            {
                this.socket = new Socket( this.url, { messageCallback: this.onMessage, onOpenCallback: this.emitReady });
            }
        },

        beforeDestroy()
        {
            this.socket.close();
        },

        methods: {
            reInit()
            {
                if( this.preventSocketsOpening )
                {
                    return;
                }

                this.socket = new Socket( this.url, { messageCallback: this.onMessage, onOpenCallback: this.emitReady });
            },

            subscribe( pStr )
            {
                if( !this.socket )
                {
                    this.reInit();
                }

                this.socket.subscribe( pStr );
            },

            pingBlock()
            {
                this.subscribe( '{ "op": "ping_block" }' );
            },

            startTxStream()
            {
                this.subscribe( '{ "op": "unconfirmed_sub" }' );
            },

            getQuote()
            {
                this.subscribe( '{ "op": "subscribe", "args": ["quote:XBTUSD"] }' );
            },

            onMessage( pData )
            {
                // console.log( 'message received', JSON.parse( pData.data ) );
                this.$emit( 'message', JSON.parse( pData.data ) );
            },

            stopStream()
            {
                this.socket.close();
                this.socket = null;
            },

            emitReady()
            {
                this.$emit( 'ready' );
            }
        }
    };
</script>
