<template>
    <div class="highchart-wrapper">
        <div id="chart-container"></div>
    </div>
</template>

<style lang="scss" scoped>
    .highchart-wrapper, #chart-container{
        height:100%;
    }
</style>

<script>
    import Highcharts from 'highcharts/highstock';
    import Data from 'highcharts/modules/data';
    import Exporting from 'highcharts/modules/exporting';
    import ExportData from 'highcharts/modules/export-data';

    import { coinInBtc } from '@/app/mixins';
    import { DarkUnica } from '@/vendor';

    Exporting( Highcharts );

    export default {
        props: {
            for: {
                type: String,
                default: 'stock-multiple-series'
            }
        },

        mixins: [ coinInBtc ],

        computed: {
            chartHeight()
            {
                return this.$store.getters[ 'chartHeight' ];
            },

            coins()
            {
                return this.$store.getters[ 'portfolio/coins' ];
            },

            prices()
            {
                return this.$store.getters[ 'prices/all' ];
            },

            coinsInPortfolio()
            {
                return Object.keys( this.$store.getters[ 'portfolio/coins' ] );
            },

            colors()
            {
                return this.$store.getters[ 'colors/all' ];
            }
        },

        watch: {
            chartHeight: function( pVal )
            {
                this.chart.setSize( 600, pVal );
            },

            colors: {
                deep: true,
                handler: function( pVal )
                {
                    this.updateColor();
                }
            }
        },

        mounted()
        {
            this.seriesOptions = [];

            let time = (new Date()).getTime();
            for( let i = 0; i < this.coinsInPortfolio.length; i++ )
            {
                // this.seriesOptions[ this.coinsInPortfolio[i].toLowerCase() ] = [ [ (new Date()).getTime(), this.getPrice( this.coinsInPortfolio[i] ) ] ];
                this.seriesOptions.push({ name: this.coinsInPortfolio[i].toLowerCase(), color: this.colors[ this.coinsInPortfolio[i] ], data: [] });
            }

            console.log( this.seriesOptions );

            this.initChart();
        },

        beforeDestroy()
        {
            if( this.updateInterval )
            {
                clearInterval( this.updateInterval );
            }
        },

        methods: {
            initChart()
            {
                let self = this;

                console.log( 'before chart', this.seriesOptions );
                Highcharts.setOptions( DarkUnica );
                this.chart = Highcharts.stockChart( 'chart-container', {
                    chart: {
                        events: {
                            load: function()
                            {
                                self.startDataUpdater();
                            }
                        },
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: '\'Unica One\', sans-serif'
                        },
                        plotBorderColor: '#606063'
                    },

                    time: {
                        useUTC: false
                    },

                    rangeSelector: {
                        selected: 4
                    },

                    yAxis: {
                        labels: {
                            formatter: function() {
                                return ( this.value > 0 ? ' + ' : '' ) + this.value + '%'
                            }
                        },

                        plotLines: [{
                            value: 0,
                            width: 2,
                            color: 'silver'
                        }]
                    },

                    plotOptions: {
                        series: {
                            compare: 'percent',
                            showInNavigator: true
                        }
                    },

                    tooltip: {
                        pointFormat: '<span class="series-name" style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
                        valueDecimals: 2,
                        split: true
                    },

                    responsive: {},

                    series: this.seriesOptions
                });

                console.log( this.chart.series );

                // this.chart.data.labels.push( new Date().toLocaleTimeString() );
            },

            updateColor()
            {
                // this.seriesOptions.forEach( series =>
                // {
                //     console.log( 'old, new', series.color, this.colors[ series.name ] );
                //     series.color = this.colors[ series.name ];
                // });

                this.chart.series.forEach( serie =>
                {
                    serie.color = this.colors[ serie.name ];
                    serie.graph.attr({ stroke: this.colors[ serie.name ] });

                    serie.redraw();
                });
            },

            startDataUpdater()
            {
                this.updateInterval = setInterval( () =>
                {
                    this.update();
                }, 3000 );
            },

            update()
            {
                let time = (new Date()).getTime();
                for( let i = 0; i < this.seriesOptions.length; i++ )
                {
                    let price = this.getPrice( this.seriesOptions[i].name );
                    this.seriesOptions[ i ].data.push( [ time, price ] );
                }

                this.chart.series.forEach( item =>
                {
                    if( this.coinsInPortfolio.indexOf( item.name ) > -1 )
                    {
                        let dataset = this.seriesOptions.find( coin => coin.name === item.name );
                        item.setData( dataset.data, false );
                    }
                });

                this.chart.redraw();
            },

            reflow()
            {
                this.chart.reflow();
            },

            getPrice( pCoin )
            {
                let price = null;
                switch( pCoin )
                {
                    case 'btc':
                        price = this.coins[ pCoin ] * this.prices[ 'XBTUSD' ];
                        break;
                    case 'ltc':
                        price = this.coins[ pCoin ] * this.prices[ 'LTCUSD' ];
                        break;
                    default:
                        let ticker = Object.keys( this.prices ).find( key =>
                        {
                            return key.indexOf( pCoin.toUpperCase() ) > -1;
                        });

                        price = this.coinInBtc( pCoin ) * this.prices[ 'XBTUSD' ];
                        break;
                }

                return price;
            }
        }
    };
</script>
