<template>
    <div class="progress-bar-wrapper" >
        <template >
            <p v-if="goalAmount && goalAmount > 0">Distance to Goal - <span :class="{ 'blur': discreet }">{{ goalAmount }} {{ goalCoin }}</span></p>
            <p v-else>No goal set</p>

            <div class="blocks-wrapper">
                <div v-for="n in 50" :class="{ 'block': true, 'filled': progressPercent >= n * 2 }"></div>
            </div>

            <p class="progress">{{ ( goalAmount && goalAmount > 0 ) ? Math.round( progressPercent ).toString().padStart( 3, '0' ) : '000' }}/100</p>
        </template>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        computed: {
            goalAmount()
            {
                return this.$store.getters['portfolio/goalAmount'];
            },

            goalCoin()
            {
                return this.$store.getters['portfolio/goalCoin'];
            },

            progressPercent()
            {
                if( this.goalCoin === 'btc' )
                {
                    return this.totalBtc / this.goalAmount * 100;
                }
                else if( this.goalCoin === 'eur' )
                {
                    return this.total / this.goalAmount * 100;
                }
                else if( this.goalCoin === 'usd' )
                {
                    return ( this.total * 1.20 ) / this.goalAmount * 100;
                }
            },

            portfolio()
            {
                return this.$store.getters['portfolio/coins'];
            },

            prices()
            {
                return this.$store.getters['prices/all'];
            },

            totalBtc()
            {
                let amount = 0;
                for( let coin in this.portfolio )
                {
                    amount += this.coinInBtc( coin );
                }

                return amount;
            },

            total()
            {
                if( !this.prices )
                {
                    return 0;
                }
                return this.totalBtc * this.prices.XBTUSD * this.prices.EURUSD;
            },

            discreet()
            {
                return this.$store.getters[ 'portfolio/discreet' ];
            }
        },

        methods: {
            coinInBtc( pCoin )
            {
                if( !this.prices )
                {
                    return 0;
                }

                if( pCoin === 'btc' )
                {
                    return this.portfolio[ pCoin ];
                }
                else if( pCoin === 'ltc' )
                {
                    if( !this.prices.XBTUSD )
                    {
                        return 0;
                    }

                    return this.prices[pCoin.toUpperCase() + 'USD'] / this.prices.XBTUSD * this.portfolio[ pCoin ];
                }
                else
                {
                    if( !this.prices.XBTUSD )
                    {
                        return 0;
                     }

                    return this.portfolio[ pCoin ] * this.prices[pCoin.toUpperCase() + 'BTC'];
                }
            }
        }
    };
</script>
