import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import VuexRouterSync from 'vuex-router-sync';

import store from '@/app/store/';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

VuexRouterSync.sync( store, router );

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>',
    beforeCreate()
    {
        this.$store.commit( 'loadStoreFromLocalStorage' );
    }
});

store.subscribe(( mutation, state ) =>
{
    localStorage.setItem( 'crypto-store', JSON.stringify( state ) );
});
