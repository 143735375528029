<template>
    <div class="coin-setting">
        <div @click="$emit('close')" class="close-btn">
            <i class="fas fa-times"></i>
        </div>

        <h2>Portfolio</h2>
        <div class="divider"></div>

        <div class="tags">
            <p>hue</p>
            <p>coin</p>
            <p>amount</p>
        </div>

        <div class="coin-line" v-for="(val, key) of coins">
            <input type="color" @change="setColor( key, $event )" :value="colors[ key ]" />
            <input v-model="key" />
            <input :class="{ 'blurred': discreet }" type="number" :value="coins[key]" @change="changeAmount( key, $event )" />
            <button @click="delCoin( key )">&times</button>
        </div>
        <div class="new-coin-line">
            <input type="color" />
            <input @change="addCoin"  placeholder="new coin" />
        </div>

        <h2>Goal</h2>
        <div class="divider"></div>
        <div class="goal-line">
            <input ref="goalAmount" :class="{ 'blurred': discreet }" :value="goalamount" @change="setGoal" placeholder="amount" />
            <input ref="goalCoin" :value="goalcoin" @change="setGoal" placeholder="coin (btc/usd/eur)" />
        </div>

        <h2>Misc</h2>
        <div class="divider"></div>
        <button @click="clearLocalstorage">Clear localstorage</button>
        <button @click="toggleDiscreet">Toggle discreet mode</button>
    </div>
</template>

<style lang="scss" scoped>
    .new-coin-line{
        text-align:left;
    }
</style>

<script>
    export default {
        data()
        {
            return {
                newCoin: null
            }
        },

        computed: {
            coins()
            {
                return this.$store.getters['portfolio/coins'];
            },

            goalamount()
            {
                return this.$store.getters[ 'portfolio/goal' ].amount;
            },

            goalcoin()
            {
                return this.$store.getters[ 'portfolio/goal' ].coin;
            },

            discreet()
            {
                return this.$store.getters[ 'portfolio/discreet' ];
            },

            colors()
            {
                return this.$store.getters[ 'colors/all' ];
            }
        },

        methods: {
            addCoin( e )
            {
                this.$store.dispatch( 'portfolio/editCoins', { [ e.target.value ]: 0 });
                this.$emit( 'add', e.target.value );

                e.target.value = '';
            },

            delCoin( pCoin )
            {
                this.$store.dispatch( 'portfolio/deleteCoin', pCoin );
                this.$store.dispatch( 'prices/deletePrice', pCoin );
                this.$emit( 'remove', pCoin );
            },

            changeAmount( key, e )
            {
                this.$store.dispatch( 'portfolio/editCoins', { [key]: parseFloat( e.target.value ) })
            },

            setGoal()
            {
                let amount = this.$refs.goalAmount.value;
                let coin = this.$refs.goalCoin.value.toLowerCase();

                if( amount && coin )
                {
                    this.$store.dispatch( 'portfolio/setGoal', { coin: coin, amount: parseFloat( amount ) } );
                }
            },

            clearLocalstorage()
            {
                localStorage.clear();
                location.reload();
            },

            toggleDiscreet()
            {
                this.$store.dispatch( 'portfolio/toggleDiscreet', !this.discreet );
            },

            setColor( key, event )
            {
                console.log( event, key );
                this.$store.dispatch( 'colors/changeColor', { coin: key, color: event.target.value });
            }
        }
    };
</script>
