import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        coins: {
            btc: 0,
            ltc: 0
        },
        goal: {
            coin: null,
            amount: null
        },
        chartData: null,
        discreet: null
    },

    getters: {
        coins: state => state.coins,
        goal: state => state.goal,
        goalAmount: state => state.goal.amount,
        goalCoin: state => state.goal.coin,
        chartData: state => state.chartData,
        discreet: state => state.discreet
    },

    mutations: {
        merge: ( state, payload ) =>
        {
            let coin = Object.keys( payload )[0];
            Vue.set( state.coins, coin, payload[coin] );
        },

        deleteKey: ( state, payload ) =>
        {
            Vue.delete( state.coins, payload );
        },

        setGoal: ( state, payload ) =>
        {
            state.goal.coin = payload.coin;
            state.goal.amount = payload.amount;
        },

        setChartData: ( state, payload ) =>
        {
            state.chartData = payload;
        },
        toggleDiscreet: ( state, payload ) =>
        {
            state.discreet = payload;
        }
    },

    actions: {
        editCoins: ( context, payload ) =>
        {
            context.commit( 'merge', payload );
        },

        deleteCoin: ( context, payload ) =>
        {
            context.commit( 'deleteKey', payload );
        },

        setGoal: ( context, payload ) =>
        {
            context.commit( 'setGoal', payload );
        },

        setChartData: ( context, payload ) =>
        {
            context.commit( 'setChartData', payload );
        },

        toggleDiscreet: ( context, payload ) =>
        {
            context.commit( 'toggleDiscreet', payload );
        }
    }
};
