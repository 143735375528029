<template>
    <div class="rocket" ref="animContainer"></div>
</template>

<style lang="scss">
    .rocket{
        width:100px;
        height:100px;

        left:0%;
        top:20%;

        position:absolute;

        // filter:brightness( 0.8 );
    }
</style>

<script>
    import { gsap } from '@/vendor';
    import bodymovin from 'lottie-web';

    import rocketAnimData from '@/app/data/rocketAnimData';

    export default {
        mounted()
        {
            gsap.set( '.rocket', { rotation: 90 });

            let rocketTl = gsap.timeline({ repeat: -1 });
            rocketTl.fromTo( '.rocket', { x: -200 }, { x: 200, duration: 5, ease: 'sine.inOut' }, 0 );
            rocketTl.fromTo( '.rocket', { zIndex: 5 }, { zIndex: -1 }, 5 );

            rocketTl.to( '.rocket', { x: -200, duration: 5, ease: 'sine.inOut' }, 5 );
            rocketTl.to( '.rocket', { zIndex: 5 }, 9.5 );


            let rocketScaleTl = gsap.timeline({ repeat: -1, delay: 2 });
            rocketScaleTl.fromTo( '.rocket', { scaleY: 1 }, { scaleY: -0.5, scaleX: 0.5, duration: 4, ease: 'sine.inOut' }, 0.5 );
            rocketScaleTl.to( '.rocket', { scaleY: 1, scaleX: 1, duration: 4, ease: 'sine.inOut' }, 6 );
            rocketScaleTl.to( { foo: 1 }, { foo: 2, duration: 1 }, 9 );

            rocketTl.timeScale( 0.25 );
            rocketScaleTl.timeScale( 0.25 );

            this.lottieAnim = bodymovin.loadAnimation({
                container: this.$refs.animContainer,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: rocketAnimData
            })
        }
    };
</script>
