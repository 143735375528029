<template>
    <div class="star-wrapper">
        <div class="normal-stars">
            <div v-for="n in amount" class="star"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .star-wrapper{
        position:fixed;
        left:-10%;
        top:0;
        width:120%;
        height:120vh;
        pointer-events:none;

        .normal-stars{
            width:100%;
            height:100%;
            position:relative;
        }
    }
    .star{
        position:absolute;
        width:1px;
        height:1px;

        background-color:white;
        transform-origin:center center;
    }
</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        data()
        {
            return {
                amount: 200
            }
        },

        mounted()
        {
            for( let i = 1; i< this.amount; i++ )
            {
                gsap.set( '.star:nth-of-type(' + i + ')', { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight });

                let tl = gsap.timeline({ delay: Math.random() * 10, repeatDelay: 10, repeat: -1 });
                tl.to( '.star:nth-of-type(' + i + ')', { width: 3, height: 3, repeat: 1, yoyo: true });
            }
        },

        methods: {
        //     doeHetVoorEen()
        //     {
        //         /*
        //             - spawn ster
        //             - maak traject naar buiten het scherm
        //             - set angle relatief aan midden
        //             - tween height naar 100px voor trail
        //         */

        //         let star = document.createElement( 'div' );
        //         star.setAttribute( 'class', 'moving-star' );

        //         this.$refs.movingStars.appendChild( star );

        //         let pos = {
        //             x: this.center.x + Math.max( 500, Math.random() * window.innerWidth ) * ( Math.random() >= 0.5 ? 1 : -1 ),
        //             y: this.center.y + Math.max( 500, Math.random() * window.innerHeight ) * ( Math.random() >= 0.5 ? 1 : -1 )
        //         }

        //         let angle = this.getAngle( pos );

        //         gsap.set( star, { x: this.center.x, y: this.center.y, rotation: angle + 90 });

        //         let target = {
        //             x: pos.x - this.center.x,
        //             y: pos.y - this.center.y
        //         };

        //         gsap.fromTo( star, { x: this.center.x, y: this.center.y, z: 200 }, { x: target.x * 10, y: target.y * 10, z: -200, duration: 30 });
        //         gsap.to( star, { height: 50, duration: 0.25 });
        //     },

        //     getAngle( pPos )
        //     {
        //         let angle = Math.atan2( pPos.y - this.center.y, pPos.x - this.center.x );
        //         return this.radToDegree( angle );
        //     },

        //     radToDegree( rad )
        //     {
        //         return (( rad > 0 ? rad : 2 * Math.PI + rad ) * 360 ) / ( 2 * Math.PI );
        //     }
        }
    };
</script>
