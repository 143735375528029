<template>
    <div id="app">
        <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
            <router-view :key="$route.path" ref="currentView"></router-view>
        </transition>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    /* eslint-disable */
    import { gsap } from '@/vendor';

    export default {
        name: 'App',

        mounted()
        {
            this.resizeRef = this.onResize;
            window.addEventListener( 'resize', this.resizeRef );

            this.onResize();
        },

        beforeDestroy()
        {
            window.removeEventListener( 'resize', this.resizeRef );
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    done();
                }
            },

            getAnimateOut( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    done();
                }
            },
        }
    };
</script>
