export default {
    namespaced: true,

    state: {
        btc: '#FF9900',
        ltc: '#d3d3d3',
        doge: '#E1B303',
        eth: '#555555',
        hex: '#ff04f5',
        usdt: '#53ae94',
        xrp: '#006097',
        dot: '#d64cA8',
        ada: '#3bc8c8',
        link: '#abd5f9',
        bnb: '#f3ba2f',
        bch: '#41bd3c',
        xlm: '#dbf2fa',
        aave: '#7680af',
        uni: '#ff3f9b',
        tron: '#fe121a',
        mana: '#f64e54',
        vet: '#2fa8f3',
        cel: '#4254a6',
        neo: '#89d218',
        dash: '#2573c2',
        fil: '#40c2cb',
        eos: '#000000',
        uma: '#ff4a4a',
        xvs: '#ffd976',
        rsr: '#000000',
        dcr: '#39d7ab',
        icon: '#1fc5c9',
        icx: '#1fc5c9',
        sc: '#20ee82',
        other: '#ffffff'
    },

    getters: {
        all: state => state
    },

    mutations: {
        setColor: ( state, payload ) =>
        {
            state[ payload.coin ] = payload.color;
        }
    },
    actions: {
        changeColor: ( context, payload ) =>
        {
            // payload: { coin, color };
            context.commit( 'setColor', payload );
        }
    }
};
