class BaseSocket
{
    constructor( pUrl, parameters = {})
    {
        // this.webSocketUrl = "ws://ws.blockchain.info/inv";
        this.websocket = new WebSocket( pUrl );
        this.parameters = parameters;

        this.websocket.onopen = this.onOpen.bind( this );
        this.websocket.onclose = this.onClose;
        this.websocket.onerror = this.onError;
        this.websocket.onmessage = this.onMessage.bind( this );

        this.subInterval;
    }

    onOpen()
    {
        // console.log( 'ready to send/receive', this );
        if( this.parameters.onOpenCallback )
        {
            this.parameters.onOpenCallback();
        }
    }

    onMessage( pData )
    {
        this.parameters.messageCallback( pData );
    }

    onClose()
    {
        // console.log( 'socket closed', this );
    }

    onError( pError )
    {
        // console.warn( 'socket error', pError );
    }

    subscribe( pStr )
    {
        if( this.websocket.readyState !== 1 )
        {
            this.subInterval = setInterval( () =>
            {
                this.websocket.send( pStr );
            }, 1000 );
        }
        else
        {
            if( this.subInterval )
            {
                clearInterval( this.subInterval );
            }

            this.websocket.send( pStr );
        }
    }

    close()
    {
        this.websocket.close();
    }
};

export default BaseSocket;
