<template>
    <div class="ui-wrapper">

        <svg class="gradients">
            <defs>
                <filter id="hardGlow" height="1000%" width="1000%" x="-500%" y="-500%">
                    <!-- Thicken out the original shape -->
                    <feMorphology operator="dilate" radius="7" in="SourceAlpha" result="thicken" />

                    <!-- Use a gaussian blur to create the soft blurriness of the glow -->
                    <feGaussianBlur in="thicken" stdDeviation="15" result="blurred" />

                    <!-- Change the colour -->
                    <feFlood flood-color="rgba(255,255,255, 0.5)" result="glowColor" />

                    <!-- Color in the glows -->
                    <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />

                    <!--    Layer the effects together -->
                    <feMerge>
                        <feMergeNode in="softGlow_colored"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>

                </filter>

                <filter id="softGlow" height="1000%" width="1000%" x="-500%" y="-500%">
                    <!-- Thicken out the original shape -->
                    <feMorphology operator="dilate" radius="1" in="SourceAlpha" result="thicken" />

                    <!-- Use a gaussian blur to create the soft blurriness of the glow -->
                    <feGaussianBlur in="thicken" stdDeviation="9" result="blurred" />

                    <!-- Change the colour -->
                    <feFlood flood-color="rgba(255,255,255, 0.5)" result="glowColor" />

                    <!-- Color in the glows -->
                    <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />

                    <!--    Layer the effects together -->
                    <feMerge>
                        <feMergeNode in="softGlow_colored"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>

                </filter>
            </defs>
        </svg>

        <div class="panel-zone left">
            <div class="meter-bg-wrapper left">
                <div ref="meterbg" class="meter-bg">
                    <div class="meter-wrapper">
                        <div ref="speedometerContainer" class="speed"></div>
                        <div ref="meterContainer" class="meter"></div>

                        <div ref="needle" class="needle"></div>

                        <!-- <p class="debug">{{ percentChange }}</p> -->
                    </div>
                </div>

                <div ref="fomoMeter" class="fomo-meter"></div>
            </div>
        </div>

        <div class="panel-zone right">
            <div class="meter-bg-wrapper right">

                <div ref="meterbg2" class="meter-bg">
                    <div ref="fearAndGreed" class="fear-and-greed-meter"></div>
                    <div class="progress-wrapper">
                        <v-moon-progressbar id="goalbar"></v-moon-progressbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .debug{
        position:absolute;
        bottom:1rem;
        left:1rem;
    }
    .gradients{
        position:absolute;
        opacity:0;
    }
</style>

<script>
    import bodymovin from 'lottie-web';
    import { gsap } from '@/vendor';

    import meterAnimData from '@/app/data/meter';
    import speedometer from '@/app/data/speedometer-body';
    import arrow from '@/app/data/arrow';
    import meterbg from '@/app/data/meterbg';
    import fomoMeter from '@/app/data/fomo-meter';
    import fearAndGreed from '@/app/data/fear-and-greed-meter';

    import MoonProgressBar from '@/app/components/MoonProgressBar';

    export default {
        components: {
            'v-moon-progressbar': MoonProgressBar
        },

        computed: {
            startValue()
            {
                return this.$store.getters[ 'startValue' ];
            },

            value()
            {
                return this.$store.getters[ 'value' ];
            },

            percentChange()
            {
                return ( this.value - this.startValue ) / this.startValue * 100;
            }
        },

        watch: {
            percentChange: function( pVal )
            {
                let scalar = 3;
                this.meterAnim.goToAndStop( Math.abs( 0.4 + ( pVal * scalar )), true );

                if( pVal < 0 )
                {
                    gsap.set( '.meter', { scaleX: -1 });
                }
                else
                {
                    gsap.set( '.meter', { scaleX: 1 });
                }

                gsap.to( '.needle', { rotation: 180 / 30 * pVal, duration: 0.2, ease: 'none' });
            }
        },

        mounted()
        {
            this.meterAnim = bodymovin.loadAnimation({
                container: this.$refs.meterContainer,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: meterAnimData
            });

            this.speedometerElement = bodymovin.loadAnimation({
                container: this.$refs.speedometerContainer,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: speedometer
            });

            this.arrowElement = bodymovin.loadAnimation({
                container: this.$refs.needle,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: arrow
            });

            this.meterBg = bodymovin.loadAnimation({
                container: this.$refs.meterbg,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: meterbg
            });

            this.meterBg2 = bodymovin.loadAnimation({
                container: this.$refs.meterbg2,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: meterbg
            });

            this.fomoMeter = bodymovin.loadAnimation({
                container: this.$refs.fomoMeter,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: fomoMeter
            });

            this.fearAndGreedMeter = bodymovin.loadAnimation({
                container: this.$refs.fearAndGreed,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: fearAndGreed
            });

            this.fomoMeter.setSpeed( 0.4 );

            this.getMeter();
        },

        methods: {
            getMeter()
            {
                let num = 25;
                let scalar = 3;
                this.meterAnim.goToAndStop( Math.abs( 0.4 + ( num * scalar )), true );
            }
        }
    };
</script>
