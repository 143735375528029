<template>
    <div class="landing">
        <!-- <div class="debug">
            <p v-for="( value, key ) in prices">{{ key + '-' + value }}</p>
        </div> -->
        <v-starmap></v-starmap>
        <!-- <v-moon-progressbar id="goalbar"></v-moon-progressbar> -->
        <v-moon ref="moon" id="moon"></v-moon>

        <v-stream @ready="stream.ready = true" :url="stream.socketUrl" ref="stream" @message="onMessage"></v-stream>
        <v-stream @ready="stream2.ready = true" :url="stream2.socketUrl" ref="stream2" @message="onMessage"></v-stream>

        <v-stream @ready="stream3.ready = true" :url="stream3.socketUrl" ref="stream3" @message="onMessage"></v-stream>

        <div class="setting-anchor">
            <div class="icon-row" :style="{ 'height': ( settingClosed ? 'auto' : '0' ), overflow: ( settingClosed ? 'auto' : 'hidden' ) }">
                <div class="cog" @click="toggleSettings">
                    <i class="fas fa-cog"></i>
                </div>
                <div class="info" id="joyride" @click="startJoyride">
                    <i class="fas fa-info"></i>
                </div>
            </div>
            <v-coinsettings id="coin-settings" :class="{ 'closed': settingClosed }" @close="toggleSettings" @add="getQuote" @remove="dropQuote"></v-coinsettings>
        </div>

        <h1 id="totalPortfolio" :class="{ 'discreet': discreet }" :style="{ color: color }">&euro; {{ dottify( total.toFixed( 2 ) ) }}</h1>

        <div class="chart-section-wrapper">
            <button @click="toggleChart">
                <i class="fas fa-sync-alt"></i>
            </button>
            <v-chart id="valuechangeChart" :value="total" v-if="prices && useChart === 'bars'"></v-chart>
            <v-chart2 id="valuechangeChart" :value="total" v-if="prices && useChart === 'lines'"></v-chart2>

            <v-highchart id="highchart-stocks" for="stock-series-multiple" v-if="prices && useChart === 'stock'"></v-highchart>

            <div :class="{ 'bottom-dragger': true, [useChart + '-chart']: true }" @mousedown="startAdjustingChartHeight"></div>
        </div>

        <v-meter id="percentagemeter"></v-meter>

        <div id="meter-tourhelper"></div>
        <div id="goalbar-tourhelper"></div>
    </div>
</template>

<style lang="scss" scoped>
    .debug{
        position:absolute;
        top:0;
        left:0;
        text-align:left;
        p{
            margin:0;
        }
    }
    #meter-tourhelper{
        position:absolute;
        bottom:3%;
        left:0.5%;
        width:220px;
        height:230px;
        background-color:cyan;
        opacity:0;
    }

    #goalbar-tourhelper{
        position:absolute;
        bottom:2%;
        right:0.5%;
        width:370px;
        height:70px;
        background-color:cyan;
        opacity:0;
    }

    .bottom-dragger{
        position:absolute;
        left:-1rem;

        width:1rem;
        height:0.25rem;

        border-top:1px solid white;
        border-bottom:1px solid white;

        cursor:n-resize;

        &.bars-chart{
            bottom:8px;
        }

        &.lines-chart{
            bottom:45px;
        }
    }
</style>

<script>
    import Driver from 'driver.js';
    import { gsap } from '@/vendor';

    import StarMap from '@/app/components/StarMap';

    import Stream2 from '@/app/components/Stream2';

    import Chart from '@/app/components/Chart';
    import Chart2 from '@/app/components/Chart2';
    import CoinSettings from '@/app/components/CoinSettings';
    // import MoonProgressBar from '@/app/components/MoonProgressBar';
    import Moon from '@/app/components/Moon';
    import Meter from '@/app/components/Meter';

    import HighChartComp from '@/app/components/HighChartComp';

    import { coinInBtc, getPrice } from '@/app/mixins';

    export default {
        components: {
            'v-starmap': StarMap,
            'v-stream': Stream2,
            'v-chart': Chart,
            'v-chart2': Chart2,
            // 'v-rocket': Rocket,
            'v-coinsettings': CoinSettings,
            // 'v-moon-progressbar': MoonProgressBar,
            'v-moon': Moon,
            'v-meter': Meter,

            'v-highchart': HighChartComp
        },

        mixins: [ coinInBtc, getPrice ],

        data()
        {
            return {
                running: false,
                settingClosed: true,
                stream: {
                    name: 'Bitmex',
                    socketUrl: 'wss://www.bitmex.com/realtime',
                    ready: false
                },
                stream2: {
                    name: 'HitBTC',
                    socketUrl: 'wss://api.hitbtc.com/api/2/ws',
                    ready: false
                },
                stream3: {
                    name: 'Gate.io',
                    socketUrl: 'wss://ws.gate.io/v3',
                    ready: false
                },
                // prices: {
                //     EURUSD: 0.82
                // },
                color: 'whitesmoke',

                useChart: 'bars'
            };
        },

        computed: {
            portfolio()
            {
                return this.$store.getters['portfolio/coins'];
            },

            goalAmount()
            {
                return this.$store.getters[ 'portfolio/goalAmount' ];
            },

            prices()
            {
                return this.$store.getters['prices/all'];
            },

            colors()
            {
                return this.$store.getters['colors/all'];
            },

            totalBtc()
            {
                let amount = 0;
                for( let coin in this.portfolio )
                {
                    if( isNaN( this.coinInBtc( coin ) ) )
                    {
                        continue;
                    }

                    amount += this.coinInBtc( coin );
                }

                return amount;
            },

            total()
            {
                if( !this.prices )
                {
                    return 0;
                }

                if( isNaN( this.totalBtc * this.prices.XBTUSD * this.prices.EURUSD ) )
                {
                    return 0;
                }

                // console.log( this.portfolio.ltc, this.prices.LTCUSD + ' -> ' +  this.coinInBtc( 'ltc' ) );
                // console.log( this.portfolio.btc, this.prices.XBTUSD + ' -> ' +  this.coinInBtc( 'btc' ) );
                // console.log( this.totalBtc );

                return this.totalBtc * this.prices.XBTUSD * this.prices.EURUSD;
            },

            newConicString()
            {
                if( !this.colors )
                {
                    return;
                }

                let str = '';
                let percent = 0;
                for( let coin in this.portfolio )
                {
                    let color;

                    if( !this.colors[coin] )
                    {
                        color = this.colors.other;
                    }
                    else
                    {
                        color = this.colors[coin];
                    }

                    str += color + ' ';
                    str += percent + '% ';
                    str += (percent + this.coinPercentage( coin )) + '%, ';

                    percent += this.coinPercentage( coin );
                }

                str = str.substring( 0, str.length - 2 );

                return str;
            },

            discreet()
            {
                return this.$store.getters[ 'portfolio/discreet' ];
            }
        },

        watch: {
            total: function(pVal, pOldVal)
            {
                if( pVal > pOldVal )
                {
                    gsap.fromTo( 'h1', { color: '#00ff00' }, { color: '#fefefe', duration: 1 } );
                    // gsap.fromTo( 'h1', {
                    //     textShadow: '0px 0px 30px rgb(0, 255, 0), -3px -3px 0px rgb( 0, 50, 0 ), 3px -3px 0px rgb( 0, 50, 0 ), -3px 3px 0px rgb( 0, 50, 0 ), 3px 3px 0px rgb( 0, 50, 0 )'
                    // },
                    // {
                    //     textShadow: '0px 0px 0px rgb(0, 255, 0), 0px 0px 0px rgb( 0, 255, 0 ), 0px 0px 0px rgb( 0, 255, 0 ), 0px 0px 0px rgb( 0, 255, 0 ), 0px 0px 0px rgb( 0, 255, 0 )',
                    //     duration: 1
                    // } );
                }
                else
                {
                    gsap.fromTo( 'h1', { color: '#ff0000' }, { color: '#fefefe', duration: 1 } );
                    // gsap.fromTo( 'h1', {
                    //     textShadow: '0px 0px 30px rgb(255, 0, 0), -3px -3px 0px rgb( 50, 0, 0 ), 3px -3px 0px rgb( 50, 0, 0 ), -3px 3px 0px rgb( 50, 0, 0 ), 3px 3px 0px rgb( 50, 0, 0 )'
                    // },
                    // {
                    //     textShadow: '0px 0px 0px rgb(255, 0, 0), 0px 0px 0px rgb( 50, 0, 0 ), 0px 0px 0px rgb( 50, 0, 0 ), 0px 0px 0px rgb( 50, 0, 0 ), 0px 0px 0px rgb( 50, 0, 0 )',
                    //     duration: 1
                    // } );
                }

                this.$refs.moon.reflow();

                this.$store.dispatch( 'setValue', pVal );
            },

            'stream.ready': function( pVal )
            {
                if( pVal && this.stream2.ready && !this.running )
                {
                    this.getQuotes();
                }
            },

            'stream2.ready': function( pVal )
            {
                if( pVal && this.stream.ready && !this.running )
                {
                    this.getQuotes();
                }
            },

            'stream3.ready': function( pVal )
            {
                if( pVal && this.stream3.ready && !this.running )
                {
                    this.getQuotes();
                }
            },

            'portfolio': {
                deep: true,
                handler: function( pVal, pOldVal )
                {
                    this.prepareChartData();
                }
            },

            colors: {
                deep: true,
                handler: function( pVal )
                {
                    this.prepareChartData();
                }
            }
        },

        mounted()
        {
            this.driver = new Driver();

            this.driver.defineSteps([
                {
                    element: '#totalPortfolio',
                    popover: {
                        title: 'Total portfolio value',
                        description: 'A Websocket will pull trade data from HitBTC and Bitmex. Together with your saved coins this will build the total value.',
                        position: 'top-center'
                    }
                },

                {
                    element: '#moon',
                    popover: {
                        title: 'To the moon',
                        description: 'This pie chart will display the distribution of your portfolio.',
                        position: 'left'
                    }
                },

                {
                    element: '#valuechangeChart',
                    popover: {
                        title: 'Value chart',
                        description: 'This chart displays the change in value of your portfolio, starting from when you opened the website.',
                        position: 'right'
                    }
                },

                {
                    element: '.setting-anchor',
                    popover: {
                        title: 'Settings',
                        description: '<p>Here you can enter all the different coins. Please check if your altcoin is listed on HitBTC before entering. A websocket will automatically start pulling price data when the trading pair is found.</p><p>Goal supports btc, any altcoin, and euro\'s (as "eur")</p><p>Only clear localstorage when experiencing a critical bug (this will wipe your saved coins from the list)</p>',
                        position: 'left'
                    },
                    onHighlighted: () =>
                    {
                        this.settingClosed = false;
                    },
                    onNext: () =>
                    {
                        this.settingClosed = true;
                        if( !this.goalAmount )
                        {
                            this.driver.preventMove();
                            this.driver.start(5);
                        }
                    }
                },

                {
                    element: '#goalbar-tourhelper',
                    popover: {
                        title: 'Goal bar',
                        description: 'If you\'ve set a goal for the moon, this will display your distance to it.',
                        position: 'top'
                    },
                    scrollIntoViewOptions: {
                        alignToTop: false
                    },
                    onHighlighted: () =>
                    {
                        let realBar = this.$el.querySelector( '#goalbar' );
                    },
                    onNext: () =>
                    {

                    }
                },

                {
                    element: '#meter-tourhelper',
                    popover: {
                        title: 'Percentage change',
                        description: 'This meter will display your current % change of your portfolio, which is the speed you\'re moving towards the moon.',
                        position: 'right'
                    }
                },

                {
                    element: '#joyride',
                    popover: {
                        title: 'That\'s about it',
                        description: 'Click this icon again to restart the tour.',
                        position: 'bottom-right'
                    }
                }
            ]);

            this.mouseMoveRef = this.mouseMove;
            window.addEventListener( 'mousemove', this.mouseMoveRef );

            this.mouseLeaveRef = this.mouseLeave;
            document.addEventListener( 'mouseleave', this.mouseLeaveRef );

            this.prepareChartData();
        },

        beforeDestroy()
        {
            window.removeEventListener( 'mousemove', this.mouseMoveRef );
        },

        methods: {
            getQuotes()
            {
                for( let coin in this.portfolio )
                {
                    this.getQuote( coin );
                }

                this.running = true;

                // this.$refs.stream.subscribe( '{ "op": "subscribe", "args": ["quote:XBTUSD"] }' );
                // this.$refs.stream.subscribe( '{ "op": "subscribe", "args": ["quote:LTCUSD"] }' );

                // this.$refs.stream2.subscribe( '{ "method": "subscribeTicker", "params": { "symbol": "ETHBTC" }, "id": 123 }' );
                // this.$refs.stream2.subscribe( '{ "method": "subscribeTicker", "params": { "symbol": "HEXBTC" }, "id": 123 }' );
                // this.$refs.stream2.subscribe( '{ "method": "subscribeTicker", "params": { "symbol": "DOGEBTC" }, "id": 123 }' );
            },

            getQuote( pCoin )
            {
                if( pCoin === 'btc' )
                {
                    this.$refs.stream.subscribe( '{ "op": "subscribe", "args": ["quote:XBTUSD"] }' );
                    this.$refs.stream.subscribe( '{ "op": "subscribe", "args": ["quote:XBTEUR"] }' );
                }
                else if( pCoin === 'ltc' )
                {
                    this.$refs.stream.subscribe( '{ "op": "subscribe", "args": ["quote:' + pCoin.toUpperCase() + 'USD"] }' );
                }
                else
                {
                    this.$refs.stream2.subscribe( '{ "method": "subscribeTicker", "params": { "symbol": "' + pCoin.toUpperCase() + 'BTC" }, "id": 123 }' );
                    // this.$refs.stream3.subscribe( '{ "id": 1, "method": "ticker.subscribe", "params": ["' + pCoin.toUpperCase() + '_USDT"] }' );
                }
            },

            dropQuote( pCoin )
            {
                if( pCoin === 'btc' )
                {
                    // this.$refs.stream.subscribe( '{ "op": "unsubscribe", "args": ["quote:XBTUSD"] }' );
                }
                else if( pCoin === 'ltc' )
                {
                    this.$refs.stream.subscribe( '{ "op": "unsubscribe", "args": ["quote:' + pCoin.toUpperCase() + 'USD"] }' );
                }
                else
                {
                    this.$refs.stream2.subscribe( '{ "method": "unsubscribeTicker", "params": { "symbol": "' + pCoin.toUpperCase() + 'BTC" }, "id": 123 }' );
                    // this.$refs.stream3.subscribe( '{ "id": 1, "method": "ticker.unsubscribe", "params": [] }' );
                }
            },

            onMessage( pData )
            {
                if( pData.table === 'quote' )
                {
                    // update prices from bitmex (btc and ltc)
                    this.updatePrices( pData );
                }

                if( pData.method === 'ticker' )
                {
                    // update prices from hitbtc (alts)
                    this.updatePrices2( pData );
                }

                if( pData.method === 'ticker.update' )
                {
                    this.updatePrices3( pData );
                }
            },

            onMessage2( pData )
            {
                console.log( pData );
            },

            updatePrices( pData )
            {
                // btc en ltc
                // this.$set( this.prices, pData.data[0].symbol, pData.data[0].askPrice );
                this.$store.dispatch( 'prices/setPrice', { symbol: pData.data[0].symbol, price: pData.data[0].askPrice });
            },

            updatePrices2( pData )
            {
                // others
                // this.$set( this.prices, pData.params.symbol, parseFloat( pData.params.bid ) );
                this.$store.dispatch( 'prices/setPrice', { symbol: pData.params.symbol, price: parseFloat( pData.params.bid ) });
            },

            updatePrices3( pData )
            {
                this.$store.dispatch( 'prices/setPrice', { symbol: pData.params[ 0 ], price: pData.params[1].last });
            },

            dottify( pNumber )
            {
                while( /(\d+)(\d{3})/.test( pNumber.toString() ) )
                {
                    pNumber = pNumber.toString().replace( /(\d+)(\d{3})/, '$1,$2' );
                }

                return pNumber;
            },

            toggleSettings()
            {
                this.settingClosed = !this.settingClosed;
            },

            // nieuwe functies
            /*
            coinInBtc( pCoin )
            {
                if( !this.prices )
                {
                    return 0;
                }

                if( pCoin === 'btc' )
                {
                    return this.portfolio[ pCoin ];
                }
                else if( pCoin === 'ltc' )
                {
                    if( !this.prices.XBTUSD )
                    {
                        return 0;
                    }

                    return this.prices[pCoin.toUpperCase() + 'USD'] / this.prices.XBTUSD * this.portfolio[ pCoin ];
                }
                else
                {
                    if( !this.prices.XBTUSD || !this.prices[pCoin.toUpperCase() + 'BTC'] )
                    {
                        return 0;
                    }

                    return this.portfolio[ pCoin ] * this.prices[pCoin.toUpperCase() + 'BTC'];
                }
            },
            */
            coinPercentage( pCoin )
            {
                // console.log( 'coinPercentage: ', pCoin );
                // console.log( this.portfolio[ pCoin ] );
                // console.log( this.prices[pCoin.toUpperCase() + 'BTC'] );
                // console.log( this.coinInBtc( pCoin ) );

                return this.coinInBtc( pCoin ) / this.totalBtc * 100;
            },

            prepareChartData()
            {
                let data = [];
                for( let coin in this.portfolio )
                {
                    let holdings = this.coinInBtc( coin.toLowerCase() ) * this.prices.XBTEUR;
                    let price = this.getPrice( coin.toLowerCase() );

                    if( coin !== 'btc' )
                    {
                        price *= this.prices.XBTUSD;
                    }

                    price *= this.prices.EURUSD;

                    data.push({
                        name: coin.charAt(0).toUpperCase() + coin.slice( 1 ),
                        y: this.coinPercentage( coin ),
                        color: this.colors[coin] !== 'undefined' ? this.colors[coin] : this.colors.default,
                        price: price.toFixed( 2 ),
                        holdings: holdings.toFixed( 2 )
                    });
                }

                this.$store.dispatch( 'portfolio/setChartData', data );
                this.$refs.moon.initChart();
            },

            startJoyride( e )
            {
                e.stopPropagation();

                this.driver.start();
            },

            toggleChart()
            {
                if( this.useChart === 'bars' )
                {
                    this.useChart = 'lines';
                    gsap.set( '.chart-section-wrapper', { height: '+=32' } );
                }
                else if( this.useChart === 'lines' )
                {
                    this.useChart = 'stock';
                    // gsap.set( '.chart-section-wrapper', { height: '' } );
                }
                else
                {
                    this.useChart = 'bars';
                    gsap.set( '.chart-section-wrapper', { height: '-=32' } );
                }
            },

            startAdjustingChartHeight( e )
            {
                let startY = e.pageY;
                let startHeight = this.$el.querySelector( '.chart-section-wrapper' ).getBoundingClientRect().height;

                this.moveRef = ( e ) =>
                {
                    gsap.set( '.chart-section-wrapper', { height: Math.max( 150, startHeight + ( e.pageY - startY ) ) } );
                    this.$store.dispatch( 'setChartHeight', Math.max( 150, startHeight + ( e.pageY - startY ) ) );
                }

                this.resetAdjust = ( e ) => {
                    window.removeEventListener( 'mousemove', this.moveRef );
                    window.removeEventListener( 'mouseup', this.resetAdjust );
                }

                window.addEventListener( 'mousemove', this.moveRef );
                window.addEventListener( 'mouseup', this.resetAdjust );
            },

            mouseMove( e )
            {
                let xPos = e.pageX - window.innerWidth / 2;
                let yPos = e.pageY - window.innerHeight / 2;

                gsap.to( '.moon-wrapper', { x: -xPos / 7.5, y: -yPos / 7.5, duration: 3 });
                gsap.to( '.star-wrapper', { x: -xPos / 10.0, y: -yPos / 10.0, duration: 3 });
            },

            mouseLeave()
            {
                if( this.mousetl )
                {
                    this.mousetl.kill();
                }

                this.mousetl = gsap.timeline();

                this.mousetl.to( '.moon-wrapper', { x: 0, y: 0, duration: 5 });
                this.mousetl.to( '.star-wrapper', { x: 0, y: 0, duration: 5 });
            },

            getAnimateIn()
            {
                let tl = gsap.timeline({ delay: 1 });
                tl.from( this.$el, { opacity: 0 });
                tl.from( 'body', { backgroundPositionY: '-200%' });

                return tl;
            }
        }
    };
</script>
