<template>
    <div class="chart-wrapper">
        <canvas ref="canvas" height="150"></canvas>
    </div>
</template>

<style lang="scss" scoped>
    .chart-wrapper{
    }
</style>

<script>
    import Chart from 'chart.js';

    export default {
        data()
        {
            return {
                startValue: null
            };
        },

        computed: {
            value()
            {
                return this.$store.getters.value;
            },

            currentDiff()
            {
                return this.value - this.startValue;
            },

            discreet()
            {
                return this.$store.getters[ 'portfolio/discreet' ];
            }
        },

        watch: {
            discreet: function( pVal )
            {
                this.toggleDiscreet();
            }
        },

        mounted()
        {
            this.ctx = this.$refs.canvas.getContext( '2d' );

            this.defaultOptions = {
                maintainAspectRatio: false,
                spanGaps: true,
                responsive: true,
                tooltips: {
                    enabled: true
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            display: true,
                            zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                            color: 'rgba( 255, 255, 255, 0.2 )',
                            drawOnChartArea: true,
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            display: true,
                            offsetGridLines: false,
                            zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                            color: 'rgba( 255, 255, 255, 0.2 )'
                        }
                    }]
                },
                legend: {
                    display: false
                }
            }

            this.discreetOptions = {
                maintainAspectRatio: false,
                spanGaps: true,
                responsive: true,
                tooltips: {
                    enabled: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            display: false,
                            beginAtZero: true,
                        },
                        gridLines: {
                            display: true,
                            zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                            color: 'rgba( 255, 255, 255, 0.2 )',
                            drawOnChartArea: true,
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            display: true,
                            offsetGridLines: false,
                            zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                            color: 'rgba( 255, 255, 255, 0.2 )'
                        }
                    }]
                },
                legend: {
                    display: false
                }
            };

            this.chart = new Chart( this.ctx, {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        label: 'Gains €',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        lineTension: 0.1,
                        backgroundColor: function( context )
                        {
                            let index = context.dataIndex;
                            let value = context.dataset.data[ index ];

                            let color = null;
                            if( value > 0 )
                            {
                                color = 'rgb(38 73 41)';

                                if( index > 1 && value < context.dataset.data[ index - 1 ] )
                                {
                                    color = 'rgb(78 149 84)';
                                }
                            }

                            if( value < 0 )
                            {
                                color = 'firebrick';

                                if( index > 1 && value > context.dataset.data[ index - 1 ] )
                                {
                                    color = 'rgb(89 25 36)';
                                }
                            }

                            return color;
                        }
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                                zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                                color: 'rgba( 255, 255, 255, 0.2 )',
                                drawOnChartArea: true,
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                display: false
                            },
                            gridLines: {
                                drawOnChartArea: false,
                                display: true,
                                offsetGridLines: false,
                                zeroLineColor: 'rgba( 255, 255, 255, 0.5 )',
                                color: 'rgba( 255, 255, 255, 0.2 )'
                            }
                        }]
                    },
                    legend: {
                        display: false
                    }
                }
            });

            this.toggleDiscreet();

            setTimeout( () => {
                this.startValue = this.value;
                this.updateInterval = setInterval( this.pushData, 1000 * 5 );
            }, 2000 );
        },

        beforeDestroy()
        {
            if( this.updateInterval )
            {
                clearInterval( this.updateInterval );
            }
        },

        methods: {
            pushData()
            {
                this.chart.data.labels.push( new Date().toLocaleTimeString() );
                this.chart.data.datasets.forEach( dataset =>
                {
                    dataset.data.push( this.currentDiff.toFixed(2) );
                });

                this.chart.update();
            },

            toggleDiscreet()
            {
                if( this.discreet )
                {
                    this.chart.options = this.discreetOptions;
                }
                else
                {
                    this.chart.options = this.defaultOptions;
                }

                this.chart.update();
            }
        }
    };
</script>
