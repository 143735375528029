export default {
    computed: {
        prices()
        {
            return this.$store.getters['prices/all'];
        }
    },

    methods: {
        getPrice( pCoin )
        {
            if( !this.prices )
            {
                return 0;
            }

            if( pCoin === 'btc' )
            {
                return this.prices.XBTUSD;
            }
            else if( pCoin === 'ltc' )
            {
                if( !this.prices.XBTUSD )
                {
                    return 0;
                }

                return this.prices[pCoin.toUpperCase() + 'USD'] / this.prices.XBTUSD;
            }
            else
            {
                if( !this.prices.XBTUSD || !this.prices[pCoin.toUpperCase() + 'BTC'] )
                {
                    return 0;
                }

                return this.prices[pCoin.toUpperCase() + 'BTC'];
            }
        }
    }
};
