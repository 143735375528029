/* eslint-disable */
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import DarkUnica from './highcharts-themes/dark-unica.js';

gsap.registerPlugin( ScrollTrigger );

export {
    gsap,
    ScrollTrigger,
    DarkUnica
};
