import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        prices: {
            EURUSD: 0.89
        }
    },

    getters: {
        all: state => state.prices
    },

    mutations: {
        setPrice: ( state, payload ) =>
        {
            Vue.set( state.prices, payload.symbol, payload.price );

            // stablecoins
            if( state.prices.XBTUSD && state.prices.XBTEUR )
            {
                state.prices.EURUSD = state.prices.XBTEUR / state.prices.XBTUSD;
            }

            if( state.prices.XBTUSD )
            {
                Vue.set( state.prices, 'USDTBTC', 1 / state.prices.XBTUSD );
                Vue.set( state.prices, 'USDCBTC', 1 / state.prices.XBTUSD );
                Vue.set( state.prices, 'BUSDBTC', 1 / state.prices.XBTUSD );
            }
        },

        deletePrice: ( state, payload ) =>
        {
            if( payload === 'ltc' )
            {
                Vue.delete( state.prices, 'LTCUSD' );
            }
            else
            {
                Vue.delete( state.prices, payload.toUpperCase() + 'BTC' );
            }
        }
    },

    actions: {
        setPrice: ( context, payload ) =>
        {
            context.commit( 'setPrice', payload );
        },

        deletePrice: ( context, payload ) =>
        {
            context.commit( 'deletePrice', payload );
        },
    }
};
